define("ember-modal-dialog/components/liquid-dialog", ["exports", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-dialog"], function (_exports, _basicDialog, _liquidDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _basicDialog.default.extend({
    layout: _liquidDialog.default,
    hasOverlay: true,
    containerClassNames: ['liquid-dialog'],
    variantWrapperClass: 'emd-animatable'
  });
});