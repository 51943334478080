define("ember-light-table/components/lt-infinity", ["exports", "ember-light-table/templates/components/lt-infinity"], function (_exports, _ltInfinity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _ltInfinity.default,
    inViewport: Ember.inject.service(),
    classNames: ['lt-infinity'],
    scrollableContent: null,
    scrollBuffer: 50,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var options = {
        viewportSpy: true,
        viewportTolerance: {
          bottom: this.get('scrollBuffer')
        },
        scrollableArea: this.get('scrollableContent')
      };
      var _this$get$watchElemen = this.get('inViewport').watchElement(Ember.get(this, 'element'), options),
        onEnter = _this$get$watchElemen.onEnter,
        onExit = _this$get$watchElemen.onExit;
      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('inViewport').stopWatching(this.get('element'));
    },
    didEnterViewport: function didEnterViewport() {
      Ember.get(this, 'enterViewport')();
    },
    didExitViewport: function didExitViewport() {
      Ember.get(this, 'exitViewport')();
    }
  });
});