define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YHQ14EAs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"head\",\"body\",\"foot\"],[[30,[36,6],[\"lt-head\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]],[30,[36,6],[\"lt-body\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]],[30,[36,6],[\"lt-foot\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sharedOptions\",\"tableClassNames\",\"extra\",\"tableActions\",\"table\",\"elementId\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/light-table.hbs"
    }
  });
});