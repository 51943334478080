define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tPoR0vHK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"ember-power-select-placeholder\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs"
    }
  });
});