define("ember-in-viewport/mixins/in-viewport", ["exports", "ember-in-viewport/-private/raf-admin", "ember-in-viewport/utils/can-use-dom", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/find-elem", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/utils/check-scroll-direction"], function (_exports, _rafAdmin, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _checkScrollDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var lastDirection = {};
  var lastPosition = {};
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,
    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,
    inViewport: Ember.inject.service(),
    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),
    init: function init() {
      // ensure this mixin runs first, then your component can override the options
      this._super.apply(this, arguments);
      var options = Ember.assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)
      options = Ember.assign(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      Ember.setProperties(this, options);
      Ember.set(this, '_evtListenerClosures', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (false && !(false) && Ember.deprecate('This mixin is deprecated. We suggest you migrate to the inViewport service or use the {{in-viewport}} modifier', false, {
        id: 'ember-in-viewport.mixin',
        until: '4.0.0',
        url: 'https://github.com/DockYard/ember-in-viewport#readme'
      }));
      if (!_canUseDom.default) {
        return;
      }
      var viewportEnabled = Ember.get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this.watchElement(Ember.get(this, 'element'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners(Ember.get(this, 'element'));
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var owner = Ember.getOwner(this);
      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    watchElement: function watchElement(element) {
      var _this = this;
      this._setInitialViewport(element);
      this._addObserverIfNotSpying(element);
      var viewportDidScroll = Ember.get(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        Ember.debug('[viewportDidScroll] This will be false by default in the next major release');
        this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));
      }
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = Ember.get(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event, element);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying(element) {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, Ember.run.bind(this, '_unbindIfEntered', element));
      }
    },
    _setInitialViewport: function _setInitialViewport(element) {
      var _this2 = this;
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!element || isTearingDown) {
        return;
      }
      var inViewport = Ember.get(this, 'inViewport');
      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        return Ember.run.scheduleOnce('afterRender', this, function () {
          var scrollableArea = Ember.get(_this2, 'scrollableArea');
          var viewportTolerance = Ember.get(_this2, 'viewportTolerance');
          var intersectionThreshold = Ember.get(_this2, 'intersectionThreshold');
          inViewport.watchElement(element, {
            intersectionThreshold: intersectionThreshold,
            viewportTolerance: viewportTolerance,
            scrollableArea: scrollableArea
          }, Ember.run.bind(_this2, _this2._onEnterIntersection), Ember.run.bind(_this2, _this2._onExitIntersection));
        });
      } else if (Ember.get(this, 'viewportUseRAF')) {
        inViewport.startRAF();
        var scrollableArea = Ember.get(this, 'scrollableArea');
        var viewportTolerance = Ember.get(this, 'viewportTolerance');
        var viewportSpy = Ember.get(this, 'viewportSpy');
        var enterCallback = function enterCallback() {
          var isTearingDown = _this2.isDestroyed || _this2.isDestroying;
          var viewportEntered = element.getAttribute('data-in-viewport-entered') === 'true';
          if (!isTearingDown && (viewportSpy || viewportEntered)) {
            Ember.set(_this2, 'viewportEntered', true);
            _this2.trigger('didEnterViewport');
          }
        };
        var exitCallback = function exitCallback() {
          var isTearingDown = _this2.isDestroyed || _this2.isDestroying;
          if (!isTearingDown && viewportSpy) {
            Ember.set(_this2, 'viewportEntered', false);
            _this2.trigger('didExitViewport');
          }
        };
        (0, _rafAdmin.startRAF)(element, {
          scrollableArea: scrollableArea,
          viewportTolerance: viewportTolerance,
          viewportSpy: viewportSpy
        }, enterCallback, exitCallback, inViewport.addRAF.bind(inViewport, element.id), inViewport.removeRAF.bind(inViewport, element.id));
      } else {
        return Ember.run.scheduleOnce('afterRender', this, function () {
          _this2._setViewportEntered(element);
        });
      }
    },
    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered: function _setViewportEntered(element) {
      var scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : undefined;
      var height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      var width = scrollableArea ? scrollableArea.offsetWidth + scrollableArea.getBoundingClientRect().left : window.innerWidth;
      var boundingClientRect = element.getBoundingClientRect();
      if (boundingClientRect) {
        this._triggerDidAccessViewport(Ember.get(this, 'inViewport').isInViewport(boundingClientRect, height, width, Ember.get(this, 'viewportTolerance')), Ember.get(this, 'viewportEntered'));
        if (Ember.get(this, 'viewportUseRAF') && !Ember.get(this, '_stopListening')) {
          Ember.get(this, 'inViewport').addRAF(Ember.get(this, 'elementId'), Ember.run.bind(this, this._setViewportEntered, element));
        }
      }
    },
    /**
     * Callback provided to IntersectionObserver
     * trigger didEnterViewport callback
     *
     * @method _onEnterIntersection
     */
    _onEnterIntersection: function _onEnterIntersection() {
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        Ember.set(this, 'viewportEntered', true);
      }
      this.trigger('didEnterViewport');
    },
    /**
     * trigger didExitViewport callback
     *
     * @method _onExitIntersection
     */
    _onExitIntersection: function _onExitIntersection() {
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        Ember.set(this, 'viewportEntered', false);
      }
      this.trigger('didExitViewport');
    },
    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (false && !(contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));
      var elementId = Ember.get(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };
      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;
      if (scrollDirection && directionChanged && Ember.get(this, 'viewportDidScroll')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }
      lastPosition[elementId] = newPosition;
    },
    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @param viewportEntered
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var viewportEntered = arguments.length > 1 ? arguments[1] : undefined;
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (isTearingDown) {
        return;
      }
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';
      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }
      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }
      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }
      if (triggeredEventName) {
        this.trigger(triggeredEventName);
      }
    },
    /**
     * Unbind when enter viewport only if viewportSpy is false
     *
     * @method _unbindIfEntered
     */
    _unbindIfEntered: function _unbindIfEntered(element) {
      if (Ember.get(this, 'viewportEntered')) {
        this._unbindListeners(element);
        this.removeObserver('viewportEntered', this, '_unbindIfEntered');
        Ember.set(this, 'viewportEntered', false);
      }
    },
    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent: function _debouncedEvent(methodName) {
      var _this3 = this;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      (false && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEvent', methodName));
      (false && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string'));
      Ember.run.debounce(this, function () {
        return _this3[methodName].apply(_this3, args);
      }, Ember.get(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));
      var contextEl = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(contextEl);
      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, {
        passive: true
      });
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = Ember.get(this, 'elementId');
      var context = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(context);
      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, {
          passive: true
        });
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },
    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners: function _bindListeners() {
      var _this4 = this;
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      (false && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (false && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));
      var elem = (0, _findElem.default)(context);
      var evtListener = function evtListener() {
        return _this4._debouncedEvent('_setViewportEntered', element);
      };
      this._evtListenerClosures.push({
        event: event,
        evtListener: evtListener
      });
      elem.addEventListener(event, evtListener, false);
    },
    /**
     * Remove listeners for rAF or scroll event listeners
     * Either from component destroy or viewport entered and
     * need to turn off listening
     *
     * @method _unbindListeners
     */
    _unbindListeners: function _unbindListeners(element) {
      var _this5 = this;
      Ember.set(this, '_stopListening', true);

      // if IntersectionObserver
      if (Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportEnabled')) {
        Ember.get(this, 'inViewport').unobserveIntersectionObserver(element);
      }

      // if rAF
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportUseRAF')) {
        var elementId = Ember.get(this, 'elementId');
        Ember.get(this, 'inViewport').removeRAF(elementId);
      }

      // if scroll event listeners
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = Ember.get(_this5, 'scrollableArea') || context;
          var elem = (0, _findElem.default)(context);
          var _ref = _this5._evtListenerClosures.find(function (closure) {
              return event === closure.event;
            }) || {},
            evtListener = _ref.evtListener;
          elem.removeEventListener(event, evtListener, false);
        });
      }

      // 4. last but not least
      var viewportDidScroll = Ember.get(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        this._unbindScrollDirectionListener();
      }
    }
  });
});