define('ember-crumbly/components/bread-crumb', ['exports', 'ember-crumbly/templates/components/bread-crumb'], function (exports, _breadCrumb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _breadCrumb.default,
    tagName: 'li',
    classNameBindings: ['crumbClass'],

    crumbClass: Ember.computed.oneWay('breadCrumbs.crumbClass'),
    linkClass: Ember.computed.oneWay('breadCrumbs.linkClass'),
    hasBlock: Ember.computed.bool('template').readOnly()
  });
});