define("ember-light-table/components/lt-body", ["exports", "ember-light-table/templates/components/lt-body", "ember-light-table/classes/Row"], function (_exports, _ltBody, _Row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Light Table
   */
  /**
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{#t.body multiSelect=true onRowClick=(action 'rowClicked') as |body|}}
   *     {{#body.expanded-row as |row|}}
   *       Hello <b>{{row.firstName}}</b>
   *     {{/body.expanded-row}}
   *
   *     {{#if isLoading}}
   *       {{#body.loader}}
   *         Loading...
   *       {{/body.loader}}
   *     {{/if}}
   *
   *     {{#if table.isEmpty}}
   *       {{#body.no-data}}
   *         No users found.
   *       {{/body.no-data}}
   *     {{/if}}
   *   {{/t.body}}
   * {{/light-table}}
   * ```
   *
   * @class t.body
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _ltBody.default,
    classNames: ['lt-body-wrap'],
    classNameBindings: ['canSelect', 'multiSelect', 'canExpand'],
    /**
     * @property table
     * @type {Table}
     * @private
     */
    table: null,
    /**
     * @property sharedOptions
     * @type {Object}
     * @private
     */
    sharedOptions: null,
    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * @property isInViewport
     * @default false
     * @type {Boolean}
     */
    isInViewport: false,
    /**
     * Allows a user to select a row on click. All this will do is apply the necessary
     * CSS classes and add the row to `table.selectedRows`. If `multiSelect` is disabled
     * only one row will be selected at a time.
     *
     * @property canSelect
     * @type {Boolean}
     * @default true
     */
    canSelect: true,
    /**
     * Select a row on click. If this is set to `false` and multiSelect is
     * enabled, using click + `shift`, `cmd`, or `ctrl` will still work as
     * intended, while clicking on the row will not set the row as selected.
     *
     * @property selectOnClick
     * @type {Boolean}
     * @default true
     */
    selectOnClick: true,
    /**
     * Allows for expanding row. This will create a new row under the row that was
     * clicked with the template provided by `body.expanded-row`.
     *
     * ```hbs
     * {{#body.expanded-row as |row|}}
     *  This is the content of the expanded row for {{row.firstName}}
     * {{/body.expanded-row}}
     * ```
     *
     * @property canExpand
     * @type {Boolean}
     * @default false
     */
    canExpand: false,
    /**
     * Allows a user to select multiple rows with the `ctrl`, `cmd`, and `shift` keys.
     * These rows can be easily accessed via `table.get('selectedRows')`
     *
     * @property multiSelect
     * @type {Boolean}
     * @default false
     */
    multiSelect: false,
    /**
     * When multiSelect is true, this property determines whether or not `ctrl`
     * (or `cmd`) is required to select additional rows, one by one. When false,
     * simply clicking on subsequent rows will select or deselect them.
     *
     * `shift` to select many consecutive rows is unaffected by this property.
     *
     * @property multiSelectRequiresKeyboard
     * @type {Boolean}
     * @default true
     */
    multiSelectRequiresKeyboard: true,
    /**
     * Hide scrollbar when not scrolling
     *
     * @property autoHideScrollbar
     * @type {Boolean}
     * @default true
     */
    autoHideScrollbar: true,
    /**
     * Allows multiple rows to be expanded at once
     *
     * @property multiRowExpansion
     * @type {Boolean}
     * @default true
     */
    multiRowExpansion: true,
    /**
     * Expand a row on click
     *
     * @property expandOnClick
     * @type {Boolean}
     * @default true
     */
    expandOnClick: true,
    /**
     * If true, the body block will yield columns and rows, allowing you
     * to define your own table body
     *
     * @property overwrite
     * @type {Boolean}
     * @default false
     */
    overwrite: false,
    /**
     * If true, the body will prepend an invisible `<tr>` that scaffolds the
     * widths of the table cells.
     *
     * ember-light-table uses [`table-layout: fixed`](https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout).
     * This means, that the widths of the columns are defined by the first row
     * only. By prepending this scaffolding row, widths of columns only need to
     * be specified once.
     *
     * @property enableScaffolding
     * @type {Boolean}
     * @default false
     */
    enableScaffolding: false,
    /**
     * ID of main table component. Used to generate divs for ember-wormhole and set scope for scroll observers
     *
     * @property tableId
     * @type {String}
     * @private
     */
    tableId: null,
    /**
     * @property scrollBuffer
     * @type {Number}
     * @default 500
     */
    scrollBuffer: 500,
    /**
     * @property scrollBufferRows
     * @type {Number}
     * @default 500 / estimatedRowHeight
     */
    scrollBufferRows: Ember.computed('scrollBuffer', 'sharedOptions.estimatedRowHeight', function () {
      return Math.ceil(this.get('scrollBuffer') / (this.get('sharedOptions.estimatedRowHeight') || 1));
    }),
    /**
     * @property useVirtualScrollbar
     * @type {Boolean}
     * @default false
     * @private
     */
    useVirtualScrollbar: false,
    /**
     * Set this property to scroll to a specific px offset.
     *
     * This only works when `useVirtualScrollbar` is `true`, i.e. when you are
     * using fixed headers / footers.
     *
     * @property scrollTo
     * @type {Number}
     * @default null
     */
    scrollTo: null,
    _scrollTo: null,
    /**
     * Set this property to a `Row` to scroll that `Row` into view.
     *
     * This only works when `useVirtualScrollbar` is `true`, i.e. when you are
     * using fixed headers / footers.
     *
     * @property scrollToRow
     * @type {Row}
     * @default null
     */
    scrollToRow: null,
    _scrollToRow: null,
    /**
     * @property targetScrollOffset
     * @type {Number}
     * @default 0
     * @private
     */
    targetScrollOffset: 0,
    /**
     * @property currentScrollOffset
     * @type {Number}
     * @default 0
     * @private
     */
    currentScrollOffset: 0,
    /**
     * @property hasReachedTargetScrollOffset
     * @type {Boolean}
     * @default true
     * @private
     */
    hasReachedTargetScrollOffset: true,
    /**
     * Allows to customize the component used to render rows
     *
     * ```hbs
     * {{#light-table table as |t|}}
     *    {{t.body rowComponent=(component 'my-row')}}
     * {{/light-table}}
     * ```
     * @property rowComponent
     * @type {Ember.Component}
     * @default null
     */
    rowComponent: null,
    /**
     * Allows to customize the component used to render spanned rows
     *
     * ```hbs
     * {{#light-table table as |t|}}
     *    {{t.body spannedRowComponent=(component 'my-spanned-row')}}
     * {{/light-table}}
     * ```
     * @property spannedRowComponent
     * @type {Ember.Component}
     * @default null
     */
    spannedRowComponent: null,
    /**
     * Allows to customize the component used to render infinite loader
     *
     * ```hbs
     * {{#light-table table as |t|}}
     *    {{t.body infinityComponent=(component 'my-infinity')}}
     * {{/light-table}}
     * ```
     * @property infinityComponent
     * @type {Ember.Component}
     * @default null
     */
    infinityComponent: null,
    rows: Ember.computed.readOnly('table.visibleRows'),
    columns: Ember.computed.readOnly('table.visibleColumns'),
    colspan: Ember.computed.readOnly('columns.length'),
    /**
     * fills the screen with row items until lt-infinity component has exited the viewport
     * @property scheduleScrolledToBottom
     */
    scheduleScrolledToBottom: Ember.observer('isInViewport', function () {
      if (this.get('isInViewport')) {
        /*
         Continue scheduling onScrolledToBottom until no longer in viewport
         */
        this._schedulerTimer = Ember.run.scheduleOnce('afterRender', this, this._debounceScrolledToBottom);
      }
    }),
    _prevSelectedIndex: -1,
    init: function init() {
      this._super.apply(this, arguments);

      /*
        We can only set `useVirtualScrollbar` once all contextual components have
        been initialized since fixedHeader and fixedFooter are set on t.head and t.foot
        initialization.
       */
      Ember.run.once(this, this._setupVirtualScrollbar);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setupScrollOffset();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      this._cancelTimers();
    },
    _setupVirtualScrollbar: function _setupVirtualScrollbar() {
      var _this$get = this.get('sharedOptions'),
        fixedHeader = _this$get.fixedHeader,
        fixedFooter = _this$get.fixedFooter;
      this.set('useVirtualScrollbar', fixedHeader || fixedFooter);
    },
    onRowsChange: Ember.observer('rows.[]', function () {
      this._checkTargetOffsetTimer = Ember.run.scheduleOnce('afterRender', this, this.checkTargetScrollOffset);
    }),
    setupScrollOffset: function setupScrollOffset() {
      var _this$getProperties = this.getProperties(['scrollTo', '_scrollTo', 'scrollToRow', '_scrollToRow']),
        scrollTo = _this$getProperties.scrollTo,
        _scrollTo = _this$getProperties._scrollTo,
        scrollToRow = _this$getProperties.scrollToRow,
        _scrollToRow = _this$getProperties._scrollToRow;
      var targetScrollOffset = null;
      this.setProperties({
        _scrollTo: scrollTo,
        _scrollToRow: scrollToRow
      });
      if (scrollTo !== _scrollTo) {
        targetScrollOffset = Number.parseInt(scrollTo, 10);
        if (Number.isNaN(targetScrollOffset)) {
          targetScrollOffset = null;
        }
        this.setProperties({
          targetScrollOffset: targetScrollOffset,
          hasReachedTargetScrollOffset: targetScrollOffset <= 0
        });
      } else if (scrollToRow !== _scrollToRow) {
        if (scrollToRow instanceof _Row.default) {
          var rowElement = this.element.querySelector("[data-row-id=".concat(scrollToRow.get('rowId'), "]"));
          if (rowElement instanceof Element) {
            targetScrollOffset = rowElement.offsetTop;
          }
        }
        this.setProperties({
          targetScrollOffset: targetScrollOffset,
          hasReachedTargetScrollOffset: true
        });
      }
    },
    checkTargetScrollOffset: function checkTargetScrollOffset() {
      var _this = this;
      if (!this.get('hasReachedTargetScrollOffset')) {
        var targetScrollOffset = this.get('targetScrollOffset');
        var currentScrollOffset = this.get('currentScrollOffset');
        if (targetScrollOffset > currentScrollOffset) {
          this.set('targetScrollOffset', null);
          this._setTargetOffsetTimer = Ember.run.schedule('render', null, function () {
            _this.set('targetScrollOffset', targetScrollOffset);
          });
        } else {
          this.set('hasReachedTargetScrollOffset', true);
        }
      }
    },
    toggleExpandedRow: function toggleExpandedRow(row) {
      var multiRowExpansion = this.get('multiRowExpansion');
      var shouldExpand = !row.expanded;
      if (multiRowExpansion) {
        row.toggleProperty('expanded');
      } else {
        this.get('table.expandedRows').setEach('expanded', false);
        row.set('expanded', shouldExpand);
      }
    },
    /**
     * @method _debounceScrolledToBottom
     */
    _debounceScrolledToBottom: function _debounceScrolledToBottom() {
      var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      this._debounceTimer = Ember.run.debounce(this, this.onScrolledToBottom, delay);
    },
    /**
     * @method _cancelTimers
     */
    _cancelTimers: function _cancelTimers() {
      Ember.run.cancel(this._checkTargetOffsetTimer);
      Ember.run.cancel(this._setTargetOffsetTimer);
      Ember.run.cancel(this._schedulerTimer);
      Ember.run.cancel(this._debounceTimer);
    },
    // Noop for closure actions
    onRowClick: function onRowClick() {},
    onRowDoubleClick: function onRowDoubleClick() {},
    onScroll: function onScroll() {},
    firstVisibleChanged: function firstVisibleChanged() {},
    lastVisibleChanged: function lastVisibleChanged() {},
    firstReached: function firstReached() {},
    lastReached: function lastReached() {},
    onScrolledToBottom: function onScrolledToBottom() {},
    actions: {
      /**
       * onRowClick action. Handles selection, and row expansion.
       * @event onRowClick
       * @param  {Row}   row The row that was clicked
       * @param  {Event}   event   The click event
       */
      onRowClick: function onRowClick(row, e) {
        var _this2 = this;
        var rows = this.get('table.rows');
        var multiSelect = this.get('multiSelect');
        var multiSelectRequiresKeyboard = this.get('multiSelectRequiresKeyboard');
        var canSelect = this.get('canSelect');
        var selectOnClick = this.get('selectOnClick');
        var canExpand = this.get('canExpand');
        var expandOnClick = this.get('expandOnClick');
        var isSelected = row.get('selected');
        var currIndex = rows.indexOf(row);
        var prevIndex = this._prevSelectedIndex === -1 ? currIndex : this._prevSelectedIndex;
        this._prevSelectedIndex = currIndex;
        var toggleExpandedRow = function toggleExpandedRow() {
          if (canExpand && expandOnClick) {
            _this2.toggleExpandedRow(row);
          }
        };
        if (canSelect) {
          if (e.shiftKey && multiSelect) {
            rows.slice(Math.min(currIndex, prevIndex), Math.max(currIndex, prevIndex) + 1).forEach(function (r) {
              return r.set('selected', !isSelected);
            });
          } else if ((!multiSelectRequiresKeyboard || e.ctrlKey || e.metaKey) && multiSelect) {
            row.toggleProperty('selected');
          } else {
            if (selectOnClick) {
              this.get('table.selectedRows').setEach('selected', false);
              row.set('selected', !isSelected);
            }
            toggleExpandedRow();
          }
        } else {
          toggleExpandedRow();
        }
        this.onRowClick.apply(this, arguments);
      },
      /**
       * onRowDoubleClick action.
       * @event onRowDoubleClick
       * @param  {Row}   row The row that was clicked
       * @param  {Event}   event   The click event
       */
      onRowDoubleClick: function onRowDoubleClick() {
        this.onRowDoubleClick.apply(this, arguments);
      } /* row */,
      /**
       * onScroll action - sent when user scrolls in the Y direction
       *
       * This only works when `useVirtualScrollbar` is `true`, i.e. when you are
       * using fixed headers / footers.
       *
       * @event onScroll
       * @param {Number} scrollOffset The scroll offset in px
       * @param {Event} event The scroll event
       */
      onScroll: function onScroll(scrollOffset /* , event */) {
        this.set('currentScrollOffset', scrollOffset);
        this.onScroll.apply(this, arguments);
      },
      /**
       * lt-infinity action to determine if component is still in viewport. Deprecated - please use enterViewport
       * @event inViewport
       * @deprecated Use `enterViewport` instead.
       */
      inViewport: null,
      /**
       * lt-infinity action to determine if component is still in viewport
       * @event enterViewport
       */
      enterViewport: function enterViewport() {
        var inViewport = this.get('inViewport');
        if (inViewport) {
          (false && !(false) && Ember.deprecate('lt-infinity inViewport event is deprecated please use enterViewport instead', false, {
            id: 'ember-light-table.inViewport',
            until: '2.0.0'
          }));
          inViewport();
        } else {
          this.set('isInViewport', true);
        }
      },
      /**
       * lt-infinity action to determine if component has exited the viewport
       * @event exitViewport
       */
      exitViewport: function exitViewport() {
        this.set('isInViewport', false);
      },
      firstVisibleChanged: function firstVisibleChanged(item, index /* , key */) {
        this.firstVisibleChanged.apply(this, arguments);
        var estimateScrollOffset = index * this.get('sharedOptions.estimatedRowHeight');
        this.onScroll(estimateScrollOffset, null);
      },
      lastVisibleChanged: function lastVisibleChanged() {
        this.lastVisibleChanged.apply(this, arguments);
      } /* item, index, key */,
      firstReached: function firstReached() {
        this.firstReached.apply(this, arguments);
      } /* item, index, key */,
      lastReached: function lastReached() {
        this.lastReached.apply(this, arguments);
        this.onScrolledToBottom();
      } /* item, index, key */
    }
  });
});