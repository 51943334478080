define("ember-light-table/templates/components/columns/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IoypO8iT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,11],[[35,0,[\"component\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,12],[[35,0,[\"component\"]]],[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[35,0],[35,6],[35,16],[35,15],[35,9]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,11],[[30,[36,13],[[35,9,[\"iconComponent\"]],[35,8]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,12],[[35,9,[\"iconComponent\"]]],[[\"sortIcons\",\"sortIconProperty\"],[[35,9],[35,8]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,11],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"lt-sort-icon \",[30,[36,10],[[35,9],[35,8]],null]]]],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[1,[34,14]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,17]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,7],null,[[\"column\",\"table\",\"resizeOnDrag\",\"isResizing\",\"onColumnResized\"],[[35,0],[35,6],[35,5],[30,[36,4],[[35,3]],null],[30,[36,2],[[32,0],[35,1],[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"onColumnResized\",\"action\",\"isResizing\",\"mut\",\"resizeOnDrag\",\"table\",\"lt-column-resizer\",\"sortIconProperty\",\"sortIcons\",\"get\",\"if\",\"component\",\"and\",\"label\",\"extra\",\"tableActions\",\"isResizable\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/columns/base.hbs"
    }
  });
});