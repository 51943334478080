define("ember-light-table/templates/components/lt-spanned-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wCHf3t0/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[15,0,[31,[\"lt-row \",[30,[36,1],[[35,0]],null]]]],[12],[2,\"\\n    \"],[10,\"td\"],[15,\"colspan\",[34,2]],[12],[2,\"\\n      \"],[18,1,[[35,3]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"classes\",\"html-safe\",\"colspan\",\"yield\",\"visible\",\"if\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-spanned-row.hbs"
    }
  });
});