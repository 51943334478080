define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YQGI9dso",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,3,[\"cellComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,7],[[35,3,[\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,0],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"rawValue\",\"row\",\"column\",\"table\",\"extra\",\"tableActions\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/cells/base.hbs"
    }
  });
});